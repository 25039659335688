.sidebarUl{
    list-style-type:circle !important;
}
.dotIcon{
    font-size: 10px;
    position: relative;
    top: -2px;
    margin-right: 5px;
}

.urlNameContainer{
    margin-top: 65px !important;
}
.alert {
    padding: 10px;
    background-color: #3175f7;
    color: white;
    opacity: 1;
    transition: 0.3s;
    margin: 5px;
    transition: opacity 0.5s;
 }
 .allpostData img{
     width: 60%;
 }
 #lable{
     box-shadow: 0 0 1px 0.2px grey;
     width: 83%;
     height: 39px;
     font-weight: 400;
     color: rgb(90, 90, 90);
     border-radius: 3px;
     padding-left: 12px;
     padding-top: 7px;
 }
 .admin{
     overflow-y: auto;
 }
 .video{
     width: 90px !important;
     height: 60px !important;
 }
 .slidebar-left{
     overflow-y: auto;
 }